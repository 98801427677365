import React from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { headerRoutes } from "../common/constants";
import { Link } from "react-scroll";
import Menu from "../modal/Menu";
import { useNavigate } from "react-router-dom";
import { RiMenu3Fill } from "react-icons/ri";

const Header = ({ isActive }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();

  return (
    <Flex justifyContent={{ base: "flex-start", md: "center" }} color="#fff">
      <Menu isOpen={isOpen} onClose={onClose} />
      <Box
        w="100%"
        maxW="1326px"
        pos="absolute"
        top="20px"
        pl={{ base: "20px", md: "64px" }}
        pr={{ base: "20px", md: "57px" }}
        zIndex={2}
      >
        <Flex
          display={{ base: "flex", md: "none" }}
          align="center"
          w="full"
          justifyContent="space-between"
        >
          <Box>
            <Image
              src="/assets/BizFlex-Logo.svg"
              w="96.67px"
              h="29px"
              objectFit="contain"
              onClick={() => navigate("/")}
              display={isActive ? "none" : "flex"}
            />
            <Image
              src="/assets/dark-logo.png"
              w="96.67px"
              h="29px"
              objectFit="contain"
              onClick={() => navigate("/")}
              display={isActive ? "flex" : "none"}
            />
          </Box>
          <Box>
            <Image
              src="/assets/menu.svg"
              w="20.96px"
              onClick={onOpen}
              h="14.41px"
              objectFit="contain"
              display={isActive ? "flex" : "none"}
            />
            <Box>
              <Box display={isActive ? "none" : "flex"}>
                <RiMenu3Fill color="#222425" onClick={onOpen} size="23px" />
              </Box>
            </Box>
          </Box>
        </Flex>

        <Flex
          align="center"
          display={{ base: "none", md: "flex" }}
          justifyContent="space-between"
        >
          <Flex align="center" gap="72px">
            <Image
              src="/assets/dark-logo.png"
              w="160px"
              h="34px"
              cursor="pointer"
              onClick={() => navigate("/")}
              objectFit="contain"
              display={isActive ? "flex" : "none"}
            />
            <Image
              src="/assets/BizFlex-Logo.svg"
              w="160px"
              h="34px"
              objectFit="contain"
              onClick={() => navigate("/")}
              display={isActive ? "none" : "flex"}
            />
          </Flex>

          <Flex align="center" gap="17px">
            {headerRoutes.map((item, i) => (
              <Link key={item.id} to={item.id} smooth={true} duration={500}>
                <Text
                  key={item.id}
                  onClick={() => navigate(item.path)}
                  transition=".3s ease-in-out"
                  cursor="pointer"
                  _hover={{ color: "orange" }}
                  color={isActive ? "#dcdcdc" : "dark"}
                  fontSize="12px"
                  style={{ textDecoration: "none" }}
                  display={{
                    base: "flex",
                    md: i < 2 ? "none" : "flex",
                  }}
                >
                  {item.name}
                </Text>
              </Link>
            ))}
          </Flex>

          <Flex align="center" gap="5px" display={{ base: "none", md: "flex" }}>
            <a
              href="https://bizflex.com.ng/login"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                bg="transparent"
                h="48px"
                borderRadius="12px"
                w="130px"
                transition=".3s ease-in-out"
                _hover={{ bg: "#fff", color: "dark" }}
                color="orangeBg"
                fontWeight={600}
              >
                Log in
              </Button>
            </a>
            <a
              href="https://bizflex.com.ng/register-step-1"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                border={isActive ? "1px solid #dcdcdc" : "1px solid #222425"}
                borderRadius="12px"
                h="48px"
                bg="transparent"
                w="130px"
                color={isActive ? "#fff" : "dark"}
                transition=".3s ease-in-out"
                _hover={{ bg: "#2463EB", border: "none" }}
                fontWeight={600}
              >
                Sign up
              </Button>
            </a>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;
