import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
    @font-face {
      font-family: 'Nohemi';
      src: url('/fonts/Nohemi-Light.ttf');
      src:
        local('Nohemi-Light'),
        url('/fonts/Nohemi-Light.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
    
    @font-face {
      font-family: 'Nohemi';
      src: url('/fonts/Nohemi-Medium.ttf');
      src:
        local('Nohemi-Medium'),
        url('/fonts/Nohemi-Medium.ttf') format('truetype');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }
    
    @font-face {
      font-family: 'Nohemi';
      src: url('/fonts/Nohemi-Bold.ttf');
      src:
        local('Nohemi-Bold'),
        url('/fonts/Nohemi-Bold.ttf') format('truetype');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }
    
    @font-face {
      font-family: 'Cooper';
      src: url('/fonts/Cooper Std Black.ttf');
      src:
        local('Cooper Std Black'),
        url('/fonts/Cooper Std Black.ttf') format('truetype');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'Montserrat';
      src: url('/fonts/Cooper Std Black.ttf');
      font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
   
  font-style: normal;
    }
    `}
  />
);

export default Fonts;
