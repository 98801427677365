import React from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { ArrowIcon } from "../../common/images";

const BusinessHero = () => {
  return (
    <Box color="#fff" fontFamily="Nohemi">
      <Flex
        align="center"
        gap="65px"
        position="relative"
        flexDir={{ base: "column-reverse", md: "row" }}
      >
        <Box w={{ base: "100%", md: "40%" }} position="relative">
          <Flex
            display={{ base: "flex", md: "none" }}
            w="full"
            justifyContent={{ base: "center", md: "flex-start" }}
          >
            <Image
              src="/assets/mob-bus-hero.svg"
              pos="relative"
              w="263.84px"
              h="315.47px"
              objectFit="contain"
            />
          </Flex>
          <Box w="fit-content" position="relative">
            <Image
              src="/assets/bus-hero.svg"
              pos="relative"
              w="435px"
              display={{ base: "none", md: "flex" }}
              h="522px"
              objectFit="contain"
            />
          </Box>
        </Box>

        <Box w={{ base: "100%", md: "50%" }} transition=".3s ease-in-out">
          <Text
            fontSize={{ base: "24px", md: "48px" }}
            textAlign={{ base: "center", md: "start" }}
            fontWeight={700}
            lineHeight={{ base: "28.8px", md: "57.6px" }}
          >
            Streamline Your Business registration Process
          </Text>
          <Flex justifyContent={{ base: "center", md: "flex-start" }} w="full">
            <Text
              textTransform="capitalize"
              mt="12px"
              color="#dcdcdc"
              textAlign={{ base: "center", md: "start" }}
              w={{ base: "90%", md: "unset" }}
              fontSize={{ base: "12px", md: "20px" }}
              lineHeight={{ base: "18px", md: "30px" }}
            >
              Register your business in under 5 minutes. Get instant business
              name availability and a compliance check.
            </Text>
          </Flex>

          <Flex justifyContent={{ base: "center", md: "flex-start" }}>
            <a
              href="https://bizflex.com.ng/register-step-1"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                mt={{ base: "17px", md: "57px" }}
                bg="#FF9D16"
                borderRadius={{ base: "6px", md: "12px" }}
                h={{ base: "42px", md: "52px" }}
                w={{ base: "114px", md: "179px" }}
                color="#0F172B"
                fontWeight={{ base: 400, md: 500 }}
                fontSize={{ base: "12px", md: "16px" }}
                display="flex"
                alignContent="center"
                _hover={{ bg: "#fff", color: "dark" }}
                gap="8px"
              >
                Get Started
                <Flex
                  justifyContent="center"
                  align="center"
                  w={{ base: "10px", md: "17px" }}
                  h={{ base: "10px", md: "17px" }}
                >
                  <ArrowIcon fill="#0F172B" />
                </Flex>
              </Button>
            </a>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default BusinessHero;
