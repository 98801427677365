import React from "react";
import { Box, Flex, Image, useMediaQuery } from "@chakra-ui/react";
import Header from "./Header";
import Hero from "../data/Home/Hero";
import Footer from "./Footer";
import { PATHS } from "../../routes/constants";
import { useLocation } from "react-router-dom";
import BusinessHero from "../data/BusinessRegistration/BusinessHero";

const Layout = ({ children }) => {
  const [isMobile] = useMediaQuery("(max-width: 1326px)");
  const location = useLocation();
  const isHomePage = location.pathname === PATHS.HOME;
  const isActive =
    location.pathname === PATHS.HOME || location.pathname === PATHS.REGPAGE;

  return (
    <Flex flexDir="column" minH="100vh" w="full" bg="#fff">
      <Header isActive={isActive} />
      <Flex
        flexDir="column"
        justifyContent="center"
        align="center"
        w="full"
        px={{ base: "0", md: "0", lg: "unset" }}
      >
        <Flex
          justifyContent="center"
          align="center"
          flexDir="column"
          w="full"
          display={isActive ? "flex" : "none"}
        >
          <Flex
            bg={isHomePage ? "dark" : "#2463EB"}
            minH={{ base: "", md: "100vh" }}
            pb={{ base: "5rem", md: "unset" }}
            flexDir="column"
            pt={{ base: "90px", md: "50px" }}
            justifyContent={{ base: "flex-start", md: "center" }}
            align="center"
            w="100%"
            pos="relative"
          >
            <Image
              src="/assets/mob-pattern.svg"
              pos="absolute"
              w="full"
              top="0"
              h="100vh"
              objectFit="cover"
              display={{ base: "flex", md: "none" }}
            />
            <Image
              src="/assets/pattern.svg"
              pos="absolute"
              display={{ base: "none", md: "flex" }}
              w="full"
              top="0"
              h="100vh"
            />
            <Flex
              pos="relative"
              zIndex={2}
              w={isMobile ? "100%" : "1326px"}
              px={{ base: "20px", md: "49px" }}
            >
              {isHomePage ? <Hero /> : <BusinessHero />}
            </Flex>
          </Flex>
        </Flex>

        <Flex justifyContent="center" flexDir="column" align="center" w="full">
          <Flex
            pb={{ base: "50px", lg: "50px" }}
            py={isActive ? { base: "40px", md: "24px" } : "0"}
            gap="18px"
            pos="relative"
            minH="75vh"
            align="flex-start"
            px={isActive ? { base: "20px", md: "49px" } : "0"}
            justifyContent="center"
            w={isMobile ? "100%" : "1326px"}
          >
            <Box w="full">{children}</Box>
          </Flex>
        </Flex>
      </Flex>
      <Box>
        <Footer />
      </Box>
    </Flex>
  );
};

export default Layout;
