export const ArrowIcon = ({ fill = "white" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.05713 6.47305V9.78442H13.3614V6.47305H7.05713Z" fill={fill} />
      <path
        d="M16.6737 6.47272L13.3621 9.78426L13.3609 9.78546L12.9321 9.78546L12.9321 10.2143L5.78036 17.366L8.12125 19.7069L15.703 12.1252L15.7048 18.4084L19.0158 18.4078V9.78486L19.014 8.81421L19.0146 8.81362V6.47451L19.0128 6.47272L16.6737 6.47272Z"
        fill={fill}
      />
    </svg>
  );
};
