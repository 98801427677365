export const headerRoutes = [
  {
    name: "Login",
    id: "login",
    path: "https://bizflex.com.ng/login",
    isExternal: true,
  },
  {
    name: "Sign Up",
    id: "signup",
    path: "https://bizflex.com.ng/register-step-1",
    isExternal: true,
  },
  { name: "Home", id: "home", path: "/", isExternal: false },
  { name: "Features", id: "features", path: "/", isExternal: false },
  { name: "How it works", id: "howitworks", path: "/", isExternal: false },
  { name: "Invoice", id: "invoice", path: "/", isExternal: false },
  {
    name: "Business Registration",
    id: "contact",
    path: "/business-registration",
    isExternal: false,
  },
  { name: "Become An Agent", id: "become", path: "/", isExternal: false },
];

export const toolkits = [
  {
    title: "Speed",
    img: "/assets/speed.svg",
    desc: "Register a business account from anywhere in 5 minutes",
  },
  {
    title: "Security",
    img: "/assets/security.svg",
    desc: "Peace of mind guaranteed. We prioritize your security with advanced encryption.",
  },
  {
    title: "Support",
    img: "/assets/support.svg",
    desc: "Get access to a trusted support team and resources whenever you want",
  },
];

export const howItWorks = [
  {
    title: "Create an Account",
    img: "/assets/accounts.svg",
    desc: "Sign up and create your business's corporate account.",
  },
  {
    title: "Set Up Sub-Accounts",
    img: "/assets/sub-account.svg",
    desc: "Configure sub accounts for various operations.",
  },
  {
    title: "Assign Roles",
    img: "/assets/roles.svg",
    desc: "Designate initiators and approvers for transactions.",
  },
  {
    title: "Manage Finances",
    img: "/assets/finances.svg",
    desc: "Pay bills, request cards, and generate invoices effortlessly.",
  },
  {
    title: "Track & Save",
    img: "/assets/save.svg",
    desc: "Monitor transactions and save for future needs.",
  },
];

export const features = [
  {
    title: "Business Registration Facilitation",
    desc: "Assistance with business name or LLC registration.",
    path: "/business-registration",
  },
  {
    title: "Corporate & Sub-Accounts Creation",
    desc: "Manage multiple accounts for various business operations.",
    path: "/",
  },
  {
    title: "Invoice & Receipt Management",
    desc: "Generate and send invoices and receipts.",
    path: "/",
  },
  {
    title: "Card Management",
    desc: "Request physical and virtual cards (NGN & USD).",
    path: "/",
  },
  {
    title: "Multi-Level Approval",
    desc: "Assign disbursement approval levels to key staff.",
    path: "/",
  },
  {
    title: "Payment Capabilities",
    desc: "Pay suppliers, vendors, and categorize payments.",
    path: "/",
  },
  {
    title: "Utility Payments & Airtime Purchase",
    desc: "Pay bills and buy airtime/data.",
    path: "/",
  },
  {
    title: "Savings & Investment Options",
    desc: "Salary saving and short-term savings plans.",
    path: "/",
  },
];

export const benefits = [
  {
    img: "/assets/reg.svg",
    icon: "/assets/phone-1.svg",
    title: "Business Registration",
    desc: "Start your business journey with ease. Our streamlined CAC business registration process helps you get up and running quickly.",
  },
  {
    title: "Invoicing",
    icon: "/assets/phone-2.svg",
    img: "/assets/invoice.svg",
    desc: "Generate professional invoices effortlessly. BizFlex invoicing system helps you bill clients accurately and receive payments faster.",
  },
  {
    img: "/assets/trans.svg",
    icon: "/assets/phone-3.svg",
    title: "Transactions All in One Place",
    desc: "Create and manage corporate and sub-accounts effortlessly. Simplify financial operations with our all-in-one hub, and handle bill payments and bulk transfer smoothly.",
  },
];

export const invoice = [
  {
    title: "Easy Access",
    desc: "Access your invoices anytime, anywhere. Our mobile-friendly platform ensures you can view, send, and manage your invoices on-the-go, providing flexibility and convenience.",
  },
  {
    title: "Streamlined Payments",
    desc: "Experience hassle-free payment collection with our app's invoicing feature. Say goodbye to manual tracking and hello to automatic payment reminders, ensuring you get paid on time, every time.",
  },
  {
    title: "Quick Invoicing",
    desc: "Maximize productivity with BizFlex quick and easy invoicing. Generate and send invoices in just a few clicks, giving you more time to focus on growing your business.",
  },
];

export const soon = [
  { img: "/assets/employee.svg", title: "Employee/Payroll Management" },
  { img: "/assets/earn.svg", title: "Save and Earn" },
  { img: "/assets/expense.svg", title: "Expense Management System" },
];

export const review = [
  {
    name: "Sarah M.",
    title: "Freelance Graphic Designer",
    text: "Before BizFlex, managing invoices and payments was a nightmare. Now, with their platform, everything is streamlined. I can create invoices, accept payments, and track my finances in one place. It's saved me countless hours and headaches!",
  },
  {
    name: "John L.",
    title: "Business Owner",
    text: "Since using BizFlex, our business has grown significantly. Their user-friendly platform makes account management a breeze, and the virtual cards simplify our spending. We've also been able to offer our customers more payment options, which has boosted our sales.",
  },
  {
    name: "David O.",
    title: "Tech Founder",
    text: "As a new entrepreneur, I needed a solution that wouldn't break the bank. BizFlex's affordable pricing and comprehensive features were perfect for me. The real-time transaction tracking gives me complete control over my finances, allowing me to make informed decisions for my business.",
  },
];

export const faqs = [
  {
    ques: "Who can sign up for BizFlex?",
    ans: "BizFlex is perfect for anyone involved in business activities, whether you're just starting or already established! This includes:\n•   Entrepreneurs: Get your business idea off the ground with ease.\n•   Business Owners: Streamline your financial operations and save time.\n•   Freelancers: Manage your finances and invoices efficiently.\n•   Established Businesses: Take advantage of our advanced features for all your business needs.",
  },
  {
    ques: "Is there a signup fee for BizFlex?",
    ans: "No! Signing up for BizFlex is completely free. You can create an account and explore our features without any commitment.",
  },
  {
    ques: "What do I need to open a BizFlex account?",
    ans: "Opening a BizFlex account is quick and simple. All you need is:\n•   A valid email address for communication and account verification.\n•   A government-issued ID for identity verification.",
  },
  {
    ques: "How does BizFlex keep my data secure?",
    ans: "At BizFlex, your data security is our top priority. We take several measures to ensure your information remains confidential and protected:\n•   Secure Storage: We use industry-standard practices to store your data securely on our systems.\n•   Data Encryption: Sensitive information like financial data is encrypted for an extra layer of protection against unauthorized access.\n•   No Third-Party Sharing: We never share your data with third-party companies without your explicit consent.\n•   Ongoing Security Measures: We continuously evaluate and improve our data security protocols to stay ahead of potential threats.",
  },
];

export const bizregfaqs = [
  {
    ques: "What are the benefits of registering my business on BizFlex?",
    ans: "• We handle your CAC registration, ensuring a smooth and efficient experience.\n• Get your business name registered quickly - in as fast as 3 business days.\n• You get your business registered in very affordable price \n• Gain legitimacy and credibility with customers and partners \n• You get a FREE corporate bank account and sub-accounts \n• Access various growth tools such as invoicing, virtual cards, POS, team management, savings, and lots more.",
  },
  {
    ques: "What is the difference between Business Name, LLC, and NGO registration?",
    ans: "• Business Name: A business name is simply the name under which you operate your sole proprietorship. It offers ease of setup but does not provide any separation between your personal assets and business liabilities. \n\n• Limited Liability Company (LLC): An LLC is a legal entity separate from its owner (you). This separation offers limited liability protection, meaning your personal assets (like your car or house) are generally protected from business debts and lawsuits.",
  },
  {
    ques: "When should I consider an LLC over a Business Name in Nigeria?",
    ans: "• You should consider an LLC if your business:•	Has some level of risk associated with its operations (e.g., construction, consulting). \n• Plans to grow and potentially attract investors.\n• Desire to protect your personal assets from business liabilities.",
  },
  {
    ques: "Is an NGO (Non-Governmental Organization) a suitable business structure?",
    ans: "NGOs are established for charitable or social purposes. They are not designed to generate profit. Consider an NGO if your primary focus is on social impact and making a positive difference.",
  },
  {
    ques: "What documents do I need to provide for registration?",
    ans: "This will depend on your chosen registration type. Refer to our FAQs (This will be embedded with a link)   or contact a Super Agent (embed link for super agents for details.",
  },
  {
    ques: "How long does the registration process take?",
    ans: "With BizFlex, it can be as fast as 3 business days.",
  },
];

export const footerLinks = [
  { name: "Home", id: "home" },
  { name: "Features", id: "features" },
  { name: "Invoice", id: "/" },
  { name: "Contact Us", id: "/" },
  { name: "Terms of Service", id: "terms-condition" },
  { name: "Become an Agent", id: "/" },
  { name: "Privacy Policy", id: "privacy-policy" },
  { name: "Support", id: "/" },
];

export const footerSubLinks = [
  { name: "Privacy Policy", id: "privacy-policy" },
  { name: "Terms & Condition", id: "terms-condition" },
  { name: "Business Registration Terms", id: "business-registration-terms" },
];

export const socials = [
  { img: "/assets/twitter.svg", link: "https://x.com/FastaMoni" },
  { img: "/assets/fb.svg", link: "https://www.facebook.com/fastamoni" },
  { img: "/assets/ig.svg", link: "https://www.instagram.com/fastamonihq" },
  {
    img: "/assets/linkedin.svg",
    link: "https://linkedin.com/company/fastamoni",
  },
];

export const menuSocial = [
  { img: "/assets/small-twi.svg", link: "https://x.com/FastaMoni" },
  { img: "/assets/small-fb.svg", link: "https://www.facebook.com/fastamoni" },
  {
    img: "/assets/small-ig.svg",
    link: "https://www.instagram.com/fastamonihq",
  },
  {
    img: "/assets/small-link.svg",
    link: "https://linkedin.com/company/fastamoni",
  },
];

export const streams = [
  {
    img: "",
    title: "Powerful Features",
    body: "Open a business account instantly in your company's name - no paperwork, bank visits, or verification deposits required. Save valuable time and focus on what matters.",
  },
  {
    img: "",
    title: "Reduced Costs",
    body: "Register your business for a fraction of the price and get fast approvals. Keep more money in your pocket.",
  },
];
export const diy = [
  {
    icon: "assets/user-icon.svg",
    headText: "For the Confident Self-Starter",
    bodyText:
      "If you’re comfortable with the process and have the time, we provide all the resources you need to handle the registration yourself.",
  },
  {
    icon: "assets/access-icon.svg",
    headText: "Access to CAC Guidelines",
    bodyText:
      "Our website offers comprehensive guides and step-by-step instructions to help you navigate the registration process.",
  },
  {
    icon: "/assets/Qicon.svg",
    headText: "Online Support",
    bodyText:
      "Access our dedicated FAQ section and support channels for any questions you may encounter during the DIY process.",
  },
];

export const pricing = [
  {
    title: "Business Name Registration",
    amount: "₦19,500",
  },
  {
    title: "Limited Liability Company (LLC)",
    amount: "₦65,000",
  },
  {
    title: "NGO",
    amount: "₦150,000",
  },
];
