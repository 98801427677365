import React, { useState } from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { ArrowIcon } from "../../common/images";

const Hero = () => {
  const [move, setMove] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <Box color="#fff" fontFamily="Nohemi">
      <Flex
        align="center"
        gap="110px"
        position="relative"
        flexDir={{ base: "column-reverse", md: "row" }}
      >
        <Box w={{ base: "100%", md: "40%" }} position="relative">
          <Flex
            display={{ base: "flex", md: "none" }}
            w="full"
            justifyContent={{ base: "center", md: "flex-start" }}
          >
            <Image
              src="/assets/mobile-hero.svg"
              pos="relative"
              w="316.84px"
              h="249.47px"
              objectFit="contain"
            />
          </Flex>

          <Box w="fit-content" position="relative">
            <Image
              src="/assets/hero.svg"
              pos="relative"
              w="567px"
              display={{ base: "none", md: "flex" }}
              h="450px"
              objectFit="contain"
            />

            <Image
              objectFit="contain"
              className="inv"
              src="/assets/hero-2.svg"
              pos="absolute"
              top="20px"
              left="0"
              display={{ base: "none", md: "flex" }}
            />
            <Image
              objectFit="contain"
              className="bus"
              display={{ base: "none", md: "flex" }}
              src="/assets/hero-1.svg"
              pos="absolute"
              top="33%"
              onMouseOver={() => setMove(true)}
              onMouseLeave={() => setMove(false)}
              right="-73px"
            />
            <Image
              objectFit="contain"
              src="/assets/hero-3.svg"
              display={{ base: "none", md: "flex" }}
              pos="absolute"
              bottom="10%"
              className="sub"
              left="-5px"
            />
          </Box>
        </Box>

        <Box
          w={{ base: "100%", md: "50%" }}
          pl={{ base: "", md: move ? "30px" : "" }}
          transition=".3s ease-in-out"
        >
          <Text
            fontSize={{ base: "28px", md: "48px" }}
            textAlign={{ base: "center", md: "start" }}
            fontWeight={700}
            lineHeight={{ base: "33.6px", md: "57.6px" }}
          >
            Simplify Your Business and Financial Journey with{" "}
            <span style={{ color: "#FF9D16" }}>BizFlex</span>
          </Text>
          <Text
            textTransform="capitalize"
            mt={{ base: "6.8px", md: "12px" }}
            color="#dcdcdc"
            textAlign={{ base: "center", md: "start" }}
            fontSize={{ base: "11px", md: "18px" }}
            lineHeight={{ base: "15px", md: "27px" }}
          >
            From business setup to financial management, we’re here to help your
            business succeed
          </Text>

          <Flex justifyContent={{ base: "center", md: "flex-start" }}>
            <a
              href="https://bizflex.com.ng/register-step-1"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                mt={{ base: "32px", md: "57px" }}
                bg="#2463EB"
                borderRadius={{ base: "6px", md: "12px" }}
                h={{ base: "30px", md: "52px" }}
                w={{ base: "94.18px", md: "179px" }}
                color="#fff"
                fontWeight={500}
                fontSize={{ base: "11px", md: "16px" }}
                display="flex"
                alignContent="center"
                _hover={{ bg: "#FF9D16", color: "#0F172B" }}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                gap="8px"
              >
                Get Started
                <Flex
                  justifyContent="center"
                  align="center"
                  w={{ base: "10px", md: "17px" }}
                  h={{ base: "10px", md: "17px" }}
                >
                  <ArrowIcon fill={hover ? "#0F172B" : "#fff"} />
                </Flex>
              </Button>
            </a>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Hero;
