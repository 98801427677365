import { lazy } from "react";
import WithSuspense from "../components/Loaders/WithSuspense";
import { PATHS } from "./constants";
import { Navigate } from "react-router-dom";

const { HOME, REGPAGE, TERMS, BTERMS, POLICY } = PATHS;

const Home = WithSuspense(lazy(() => import("../pages/Home/Home")));
const BusinessRegistration = WithSuspense(
  lazy(() => import("../pages/BusinessRegistration/BusinessRegistration"))
);
const Policy = WithSuspense(lazy(() => import("../pages/Policies/Policies")));
const Terms = WithSuspense(lazy(() => import("../pages/Terms/Terms")));
const BTerms = WithSuspense(
  lazy(() => import("../pages/BusinessRegistrationTerms/BTerms.js"))
);

export const ROUTES = [
  { path: HOME, element: <Home /> },
  { path: REGPAGE, element: <BusinessRegistration /> },
  { path: "*", element: <Navigate to="/" replace /> },
  { path: POLICY, element: <Policy /> },
  { path: TERMS, element: <Terms /> },
  { path: BTERMS, element: <BTerms /> },
];
